import React from "react";
import Image from "components/Image";

const Jumbotron = ({ heading, image }) => {
  return (
    <div className="flex relative">
      {heading && (
        <div className="absolute top-1/2 left-1/2 z-10 transform -translate-y-1/2 -translate-x-1/2 text-3xl md:text-6xl text-center tracking-wider font-semibold text-white filter drop-shadow-text">
          {heading}
        </div>
      )}
      {image && <Image image={image} />}
    </div>
  );
};

export default Jumbotron;
