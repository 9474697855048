import React from "react";
import { Link, graphql } from "gatsby";
import { BsArrowRight } from "react-icons/bs";

import Layout from "components/bespoke/Layout";
import Jumbotron from "components/Jumbotron";
import Card from "components/Card";

export const query = graphql`
  {
    wp {
      page(id: "/bespoke/projects/", idType: URI) {
        id
        title
        slug
        projects {
          heading
          content
          image {
            ...MediaItem
          }
        }
      }
      projects {
        nodes {
          id
          uri
          title
          featuredImage {
            node {
              ...MediaItem
            }
          }
          singleProject {
            detail {
              category
              location
            }
          }
        }
      }
    }
  }
`;

const Projects = ({ data }) => {
  const {
    title,
    projects: { heading, content, image },
  } = data.wp.page;
  const projects = data.wp.projects.nodes;
  return (
    <Layout title={title}>
      <main>
        <div className="bg-two-tone from-firefly to-white">
          <div className="md:container mx-6 md:mx-auto">
            <h2 className="text-3xl lg:text-4xl tracking-wider uppercase text-white text-center font-bold">
              {heading}
            </h2>
            <div className="my-16 grid md:grid-cols-2 gap-12 md:px-6 lg:px-0">
              {projects.map((project) => {
                return (
                  <Card
                    theme={`stormdust`}
                    key={project.id}
                    image={project.featuredImage && project.featuredImage.node}
                    title={project.title}
                    category={project.singleProject.detail.category}
                    location={project.singleProject.detail.location}
                    link={`${project.uri}`}
                    className={`h-[300px] lg:h-[350px]`}
                  />
                );
              })}
            </div>

            <div className="flex flex-col mb-10 md:px-6 lg:px-0 py-8 text-center text-2xl md:text-3xl text-firefly-500 tracking-wider font-bold space-y-8 mx-6 md:mx-auto max-w-prose">
              <div
                className="space-y-8"
                dangerouslySetInnerHTML={{ __html: content }}
              ></div>
              <Link
                className={`mx-auto flex items-center text-center  uppercase text-stormdust tracking-wider font-bold`}
                label={``}
                to={`/bespoke/contact/`}
              >
                Get in touch
                <BsArrowRight className={`ml-2 text-firefly-500 text-5xl`} />
              </Link>
            </div>
          </div>
        </div>
        <Jumbotron image={image} />
      </main>
    </Layout>
  );
};

export default Projects;
