import React from "react";
import { Link } from "gatsby";

import Image from "components/Image";
import LinkInternal from "components/LinkInternal";

const Card = ({ theme, image, title, location, category, link, className }) => {
  return (
    <Link to={link} className="group">
      <div
        className={`flex flex-col group-hover:shadow-lg group-hover:transition group-hover:transform group-hover:-translate-y-2 theme-${theme}`}
      >
        <figure className="w-full flex">
          {image && <Image image={image} className={className} />}
        </figure>
        <figcaption
          className={`bg-skin-fill transition duration-300 group-hover:bg-firefly text-skin-base py-4 px-8`}
        >
          <div className="">
            <div className="text-lg font-bold uppercase tracking-wider leading-none">
              {title}
            </div>
            <p className="text-sm font-light tracking-wide">{location}</p>
          </div>

          <div className="mt-4 flex justify-between">
            {category && <p className="text-sm tracking-wide">{category}</p>}
            <LinkInternal className="ml-auto" label="Read more" target={link} />
          </div>
        </figcaption>
      </div>
    </Link>
  );
};

export default Card;
