import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import GlobalLayout from "components/GlobalLayout";
import Nav from "components/Nav";
import MobileNav from "components/MobileNav";
import Footer from "components/Footer";
import ContactBar from "components/ContactBar";

const BespokeMenu = () => {
  const data = useStaticQuery(
    graphql`
      {
        wp {
          menu(idType: NAME, id: "Bespoke Menu") {
            id
            menuItems {
              nodes {
                id
                label
                path
              }
            }
          }
          themeGeneralSettings {
            globalOptions {
              contactBannerMessage
              instagram
              linkedin
              twitter
              address
              phone
              email
            }
          }
        }
      }
    `
  );
  return data.wp;
};

const Layout = ({ title, page, children, bespokeHome }) => {
  const data = BespokeMenu();
  return (
    <GlobalLayout title={title}>
      <Nav
        theme={`bespoke`}
        menu={data.menu}
        logoLink="/bespoke"
        instagram={data.themeGeneralSettings.globalOptions.instagram}
        twitter={data.themeGeneralSettings.globalOptions.twitter}
        linkedin={data.themeGeneralSettings.globalOptions.linkedin}
        bespokeHome={bespokeHome}
      />
      <MobileNav theme={`bespoke`} menu={data.menu} logoLink="/bespoke" />
      {children}
      <ContactBar
        theme={`stormdust`}
        link={`/bespoke/contact`}
        contactBannerMessage={
          data.themeGeneralSettings.globalOptions.contactBannerMessage
        }
      />
      <Footer
        theme={`bespoke`}
        page={page}
        menu={data.menu}
        address={data.themeGeneralSettings.globalOptions.address}
        email={data.themeGeneralSettings.globalOptions.email}
        phone={data.themeGeneralSettings.globalOptions.phone}
        instagram={data.themeGeneralSettings.globalOptions.instagram}
        twitter={data.themeGeneralSettings.globalOptions.twitter}
        linkedin={data.themeGeneralSettings.globalOptions.linkedin}
      />
    </GlobalLayout>
  );
};

export default Layout;
